import { Component } from '@angular/core';
import {MatButton} from "@angular/material/button";
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-logout-popup',
  standalone: true,
  imports: [
    MatButton,
    MatDialogModule
  ],
  templateUrl: './logout-popup.component.html',
  styleUrl: './logout-popup.component.scss'
})
export class LogoutPopupComponent {

  constructor(private dialogRef: MatDialogRef<LogoutPopupComponent>) {
  }

  closeModal() {
    this.dialogRef.close(); // Close the dialog
  }

  confirmAction() {
    this.dialogRef.close('confirm');
  }
}
