import {CommonModule} from '@angular/common';
import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {RouterLink, RouterOutlet} from "@angular/router";
import {MatSidenav, MatSidenavContainer, MatSidenavContent} from "@angular/material/sidenav";
import {MatListItem, MatNavList} from "@angular/material/list";
import {MatIcon} from "@angular/material/icon";
import {BreakpointObserver} from "@angular/cdk/layout";
import {ProjectService} from "../../../core/services/project.service";
import {AuthService} from "../../../core/services/auth.service";

@Component({
  selector: 'app-side-bar',
  standalone: true,
  imports: [
    RouterLink,
    CommonModule,
    MatSidenavContainer,
    MatNavList,
    MatListItem,
    MatIcon,
    RouterOutlet,
    MatSidenav,
    MatSidenavContent
  ],
  templateUrl: './side-bar.component.html',
  styleUrl: './side-bar.component.scss'
})

export class SideBarComponent implements OnInit, OnChanges {
  @ViewChild('sidenav') sidenav!: MatSidenav;
  @Input() isMobile: boolean = true;
  @Input() toggleMenuEvent: boolean = false;
  showMenu1 = true;
  showMenu2 = false;


  constructor(private observer: BreakpointObserver, public projectService: ProjectService, public authService: AuthService) {
  }


  ngOnInit() {
    this.observer.observe(['(max-width: 800px)']).subscribe((screenSize) => {
      this.isMobile = screenSize.matches;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['toggleMenuEvent']) {
      this.sidenav?.toggle();
    }
  }

  showHideMenu(id: any) {
    if (id == 1) {
      this.showMenu1 = !this.showMenu1;
    } else if (id == 2) {
      this.showMenu2 = !this.showMenu2;
    }

  }

  toggle() {
    this.sidenav?.toggle();

  }
  // Assuming projectService is already injected and contains the user data

  getInitials(name: string): string {
    if (!name) return '';
    const names = name.split(' ');
    const initials = names.map(n => n[0]).join('');
    return initials.toUpperCase();
  }

}
