import {Component, EventEmitter, Output} from '@angular/core';
import {Router, RouterLink} from '@angular/router';
import {MatToolbar} from "@angular/material/toolbar";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatSidenav} from "@angular/material/sidenav";
import {MatTooltip} from "@angular/material/tooltip";
import {AuthService} from "../../../core/services/auth.service";
import {MatDialog} from "@angular/material/dialog";
import {LogoutPopupComponent} from "../../popups/logout-popup/logout-popup.component";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterLink, MatToolbar, MatIcon, MatIconButton, MatTooltip],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  @Output() menuToggle = new EventEmitter<void>();

  constructor(private authService: AuthService, private router: Router, private dialog: MatDialog) {
  }

  toggleMenu() {
    console.log('toggled')
    this.menuToggle.emit();
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutPopupComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        // Perform logout action
        this.authService.logout();
        this.router.navigateByUrl('login');
      } else {
      }
    });
  }
}
