<div  class="bg-white flex justify-between items-center">

  <div class="flex items-center">
    <button mat-icon-button aria-label="Menu icon" class="p-0" (click)="toggleMenu()">
    <mat-icon>menu</mat-icon>
  </button>
    <h1 class="mt-2">Rajwada World</h1>
  </div>
  <div class="mr-5 mt-2">
    <mat-icon (click)="logout()"  class="cursor-pointer" matTooltip="Logout">exit_to_app</mat-icon>
  </div>
</div>


